<script>
	let name = 'skovseth.no';
</script>

<main>
	<h1>{name}</h1>
    <h2><a href="https://www.skovseth.no/familiens_filmer/">Familiens filmer</a></h2>
    <h2><a href="https://www.skovseth.no/hubspot/">HubSpot</a></h2>
    <h2><a href="https://www.skovseth.no/middagsplanlegger/">Middagsplanlegger</a></h2>
    <h2><a href="https://www.skovseth.no/motorsportkalender/">Motorsportkalender</a></h2>
    <h2><a href="https://www.skovseth.no/pureservice-fargekart/">Pureservice fargekart</a></h2>
    <h2><a href="https://www.skovseth.no/togtabell/">Togtabell</a></h2>
    <h2><a href="https://www.skovseth.no/tomorrowland/">Tomorrowland 2023</a></h2>
    <h2><a href="https://www.skovseth.no/fredagsmys/">Vinlotteri</a></h2>
</main>